<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="6" align="middle">
					<Stack direction="column" align="left" space="3">
						<Heading size="4" color="primary" uppercase>Our Benefits</Heading>
						<Heading size="2">Get More Traffic <br/> On Your Website</Heading>
						<Paragraph micro>Aliquam ut turpis id nibh maximus interdum nec eu urna. In vel aliquam metus, a malesuada ex. Sed at dictum leo, quis bibendum leo. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent lorem lorem, blandit eu nisi in, ullamcorper condimentum tortor. Pellentesque enim leo, facilisis vel aliquet vitae, suscipit a leo.</Paragraph>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="1">
								<Icon icon="arrow_right" color="primary" size="medium" />
								<Heading size="5">Global Reach</Heading>
							</Stack>
							<Stack direction="row" space="1">
								<Icon icon="arrow_right" color="primary" size="medium" />
								<Heading size="5">Big Experience</Heading>
							</Stack>
						</Stack>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="1">
								<Icon icon="arrow_right" color="primary" size="medium" />
								<Heading size="5">Convenience</Heading>
							</Stack>
							<Stack direction="row" space="1">
								<Icon icon="arrow_right" color="primary" size="medium" />
								<Heading size="5">Team Strength</Heading>
							</Stack>
						</Stack>
						<Button label="Get Started Now" color="primary" />
					</Stack>
				</GridContainer>
				<GridContainer size="6" align="middle">
					<Images width="100%" image="theme-ed/tech3.png" />
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Slider time="10000" height="400px" type="dots">
				<SliderContainer>
					<Grid>
						<GridContainer size="4">
							<Card>
								<CardSection>
									<Stack direction="column" space="2" align="left">
										<Icon icon="search" background="primary" color="light" size="large" />
										<Heading size="4">Keyword Research</Heading>
										<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna.</Paragraph>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="4">
							<Card>
								<CardSection>
									<Stack direction="column" space="2" align="left">
										<Icon icon="sort" background="secondary" color="light" size="large" />
										<Heading size="4">Content Marketing</Heading>
										<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna.</Paragraph>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="4">
							<Card>
								<CardSection>
									<Stack direction="column" space="2" align="left">
										<Icon icon="send" background="tertiary" color="light" size="large" />
										<Heading size="4">PPC Advertising</Heading>
										<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna.</Paragraph>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
					</Grid>
				</SliderContainer>
				<SliderContainer>
					<Grid>
						<GridContainer size="4">
							<Card>
								<CardSection>
									<Stack direction="column" space="2" align="left">
										<Icon icon="link" background="primary" color="light" size="large" />
										<Heading size="4">Competitor Research</Heading>
										<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna.</Paragraph>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="4">
							<Card>
								<CardSection>
									<Stack direction="column" space="2" align="left">
										<Icon icon="share" background="secondary" color="light" size="large" />
										<Heading size="4">Social Media Marketing</Heading>
										<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna.</Paragraph>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
						<GridContainer size="4">
							<Card>
								<CardSection>
									<Stack direction="column" space="2" align="left">
										<Icon icon="sort" background="tertiary" color="light" size="large" />
										<Heading size="4">One Page SEO</Heading>
										<Paragraph micro>Nunc rhoncus, nisi vel consequat lobortis, ante eros bibendum ligula, eget cursus risus urna.</Paragraph>
									</Stack>
								</CardSection>
							</Card>
						</GridContainer>
					</Grid>
				</SliderContainer>
			</Slider>
		</Section>
		
		<Section color="primary">
			<Grid>
				<GridContainer size="6" align="middle">
					<Images width="100%" image="theme-ed/tech1.png" />
				</GridContainer>
				<GridContainer size="6" align="middle">
					<Stack direction="column" align="left" space="3">
						<Heading size="2">We Offer a Full Range of Digital Marketing Services!</Heading>
						<Paragraph micro>Maecenas aliquam rhoncus nunc, vitae lobortis lectus pulvinar nec. Duis quis accumsan lorem, non dignissim neque. Morbi mattis condimentum urna, vehicula dapibus tellus egestas et. Sed ac augue dui. Cras interdum ante id lorem porttitor, vitae placerat magna fermentum.</Paragraph>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Global Reach</Heading>
									<Paragraph micro>Up to 100%</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Big Experience</Heading>
									<Paragraph micro>Expert Worker</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Stack width="100%" direction="column row@md" space="3">
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Convenience</Heading>
									<Paragraph micro>To reach your target</Paragraph>
								</Stack>
							</Stack>
							<Stack direction="row" space="3">
								<Icon icon="check" color="primary" size="large" />
								<Stack direction="column" align="left">
									<Heading size="5">Team Strength</Heading>
									<Paragraph micro>Clients Satisfaction</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Button label="Start a Project" color="primary" />
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
		<Section>
			<Stack direction="column" space="3">
				<Heading size="5" color="primary" uppercase>Our Pricing</Heading>
				<Heading size="2" align="center">Take a look at our Pricing and<br/>Select Your Choice</Heading>
				<Grid>
					<GridContainer size="12 4@md">
						<Card>
							<CardSection>
								<Stack direction="column" space="3">
									<Heading size="5">Trial Version</Heading>
									<Heading size="2">Free</Heading>
									<Icon icon="send" background="light" color="dark" size="large" />
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>12 Keywords Optimized</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>6 Top 10 Rankings</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Website Analysis</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Keyword Research</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Content Optimization</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Data Control</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Button label="Try for Free" color="primary" type="outline" />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Card>
							<CardSection>
								<Stack direction="column" space="3">
									<Heading size="5">Regular</Heading>
									<Stack justify="center">
										<Heading size="2" margin="none">$29</Heading>
										<Paragraph margin="none" micro>/Mo</Paragraph>
									</Stack>
									<Icon icon="star" background="light" color="primary" size="large" />
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>12 Keywords Optimized</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>6 Top 10 Rankings</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Website Analysis</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Keyword Research</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Content Optimization</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Data Control</Paragraph>
										<Icon icon="close" color="light" background="tertiary" size="small" />
									</Stack>
									<Button label="Get Started" color="primary" />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Card>
							<CardSection>
								<Stack direction="column" space="3">
									<Heading size="5">Extended</Heading>
									<Stack justify="center">
										<Heading size="2" margin="none">$59</Heading>
										<Paragraph margin="none" micro>/Mo</Paragraph>
									</Stack>
									<Icon icon="work" background="light" color="dark" size="large" />
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>12 Keywords Optimized</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>6 Top 10 Rankings</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Website Analysis</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Keyword Research</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Content Optimization</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Stack justify="spaceBetween" width="100%" space="3">
										<Paragraph micro>Data Control</Paragraph>
										<Icon icon="checkmark" color="light" background="secondary" size="small" />
									</Stack>
									<Button label="Get Started" color="primary" type="outline" />
								</Stack>
							</CardSection>
						</Card>
					</GridContainer>
				</Grid>
			</Stack>
		</Section>
		<Section>
			<Grid>
				<GridContainer size="6">
					<Images width="100%" image="theme-ed/tech4.png" />
				</GridContainer>
				<GridContainer size="6">
					<Stack direction="column" align="left" space="3">
						<Heading size="2">What people say <br/> About our Services</Heading>
						<Slider time="10000" height="250px" type="dots" collapse>
							<SliderContainer>
								<Paragraph micro>Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst.</Paragraph>
								<Heading size="5" color="primary">Droila Abhi</Heading>
								<Paragraph micro>Marketing Manager</Paragraph>
							</SliderContainer>
							<SliderContainer>
								<Paragraph micro>Pellentesque vel tincidunt purus. Maecenas ultricies, urna dictum finibus luctus, tortor ligula blandit libero, ut accumsan nibh tellus et lacus. In hac habitasse platea dictumst.</Paragraph>
								<Heading size="5" color="primary">Droila Abhi</Heading>
								<Paragraph micro>Marketing Manager</Paragraph>
							</SliderContainer>
						</Slider>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/edison/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
